import React from 'react';
import './aside.css';   

const Aside = () => {
    return (
        <div className='marqueeAsideContainer'> 
            <span className='marqueeAsideText'>
            ∞ This site contains content meant purely for my own amusement. ∞ Explore at your own risk. ∞ 
            </span>
        </div>
    );
};

export default Aside;
