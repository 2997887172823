import React from 'react';
import './diamondMenuColumn.css';

const DiamondMenuColumn = () => {
  return (
  <div>
    <div className="gallery">
        <img className='' src="https://picsum.photos/300/300?random=1" alt='short resume'/>
        <img className='' src="https://picsum.photos/300/300?random=2" alt='expierice in picture form' />
        <img className='' src="https://picsum.photos/300/300?random=3" alt='expierice in picture form use videos for classified work with a explaination' />
        <img className='' src="https://picsum.photos/300/300?random=4" alt='code snippets' />
    </div>
    <div className='circleContainer'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
    </div>
  </div>
  );
};

export default DiamondMenuColumn;
