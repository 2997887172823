import React from 'react';
import MarqueeTextMenu from '../marqueeTextMenu/MarqueeTextMenu';
import './marqueeContainer.css';


const MarqueeContainer = () => {
  return (
    <div className='marqueeContainer'>
        <MarqueeTextMenu titleForLink={'About'} textFollowingTitle={'who am i. what am i.'} speed={1}/>   
        <MarqueeTextMenu titleForLink={'Contact'} textFollowingTitle={'email maybe. send me a owl.'} speed={1}/>  
        <MarqueeTextMenu titleForLink={'Experiments'} textFollowingTitle={'projects that may be useful.'} speed={1}/>
        <MarqueeTextMenu titleForLink={'Projects'} textFollowingTitle={'projects that i have worked on.'} speed={1}/>
        <MarqueeTextMenu titleForLink={'Blog'} textFollowingTitle={'this may not work for me...lets see.'} speed={1}/>
        <MarqueeTextMenu titleForLink={'Ideas'} textFollowingTitle={'maybe a living kanban (No Idea).'} speed={1}/>
        <MarqueeTextMenu titleForLink={'Longitude'} textFollowingTitle={'where am i, most of the time.'} speed={1}/>
        <MarqueeTextMenu titleForLink={'Random'} textFollowingTitle={'i will take you to a random place.'} speed={1}/>   
        <div className='littleBitAtTheEndOfTheMenu' data-text="Ρεαχη φορ τηε Σκψ.">Ρεαχη φορ τηε Σκψ.</div>
    </div>
  );
};

export default MarqueeContainer;
