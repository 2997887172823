import React from 'react';
import './header.css'
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className="titleContainer w-full">
            <Link to="/">
                    <span className="titleText">
                        <span className='titleText-L'>L</span>
                        <span className='titleText-R'>r</span>
                        <span className='titleText-0'>4</span>
                        <span className='titleText-02'>0</span>
                        <span className='titleText-t'>4</span>
                    </span>
            </Link>
            
        </div>
            );
};

export default Header;
