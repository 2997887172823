import React from 'react';
import Header from '../header/Header';
import Aside from '../aside/Aside';
import DiamondMenuColumn from '../diamondMenuColumn/diamondMenuColumn';
//import MarqueeTextMenu from '../marqueeTextMenu/MarqueeTextMenu';
import MarqueeContainer from '../marqueeContainer/marqueeContainer';

const Layout = ({ children }) => {
  return (
    <div className="grid grid-rows-5 grid-cols-[20%_5%_19%_26%_30%] h-screen layoutGrid">     
      <div className="row-span-5">
          <Header />
      </div>
      <div className="disclaimer row-span-5">
         <Aside className='disclaimerContainer'/>
      </div>
      
      <div className='row-span-5 diamondMenuContainer border-l border-r'>
         <DiamondMenuColumn className='diamondMenuContainer'/> 
      </div>
      <div className='row-span-5 border-r content-stretch'>
         <MarqueeContainer/>
      </div>
      <div className="mainContent h-screen row-span-5"> 
      {/**  toggling the property in app.js so i can change views here */}
        {children}
      </div>
    </div>
  );
};

export default Layout;