import React, { useEffect, useState } from 'react';
import './home.css';   
import gearImage from '../../assets/images/guiltyGear_resized.svg';


const Home = () => {
  const dutyTexts = ['code', 'develop', 'engineer', 'design'];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % dutyTexts.length);
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [dutyTexts.length]);
  
  return (      
   

 <div className='homeContainer'>
  <div className='intro'>
    <div className='introText'>I'm Luis Raffucci, a web developer from Houston, Texas, specializing in JavaScript, HTML, and CSS. Living in Texas but working with clients worldwide, I invite you to explore my projects. See where I've been and what I've done, and to get to know me.</div>
  </div>
  <div className='gearArea'> <img className="guiltyGear spin" srcSet={gearImage} /></div>
</div> 
    

    );
};

export default Home;
