import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import React, { useEffect, useState } from 'react';
import Layout from './components/layout/Layout';
import Home from './components/home/home';


function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch('http://localhost:8000/api/hello.php')
    .then(response => response.json())
    .then(data => setMessage(data.message));
  
  }, []);
  
  return (
      <Router>
        {/* <MouseFollower /> 

        */}
        <Layout>
          <Home/>
        </Layout>
      </Router>
  );
}

export default App;
